import type { EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IUser } from 'src/types';
import { usersCollectionAdapter } from './entityAdapter';
import { userCollectionThunks } from './thunks';

type InitialStateType = {
  users: EntityState<IUser>;
  status: 'idle' | 'loading' | 'failed';
};

const getInitialState = (): InitialStateType => ({
  users: usersCollectionAdapter.getInitialState(),
  status: 'idle',
});

const usersCollectionSlice = createSlice({
  name: 'usersCollection',
  initialState: getInitialState(),
  reducers: {
    addUserToCollection(store, action: PayloadAction<IUser>) {
      usersCollectionAdapter.upsertOne(store.users, action.payload);
    },
    removeUserFromCollection(store, action: PayloadAction<number>) {
      usersCollectionAdapter.removeOne(store.users, action.payload);
    },
    updateUserInCollection(store, action: PayloadAction<Partial<IUser> & { userId: number }>) {
      usersCollectionAdapter.updateOne(store.users, {
        id: action.payload.userId,
        changes: action.payload,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userCollectionThunks.getUserCollectionThunk.pending, (store) => {
      store.status = 'loading';
    });

    builder.addCase(userCollectionThunks.getUserCollectionThunk.rejected, (store) => {
      store.status = 'failed';
    });

    builder.addCase(userCollectionThunks.getUserCollectionThunk.fulfilled, (store, { payload }) => {
      store.users = usersCollectionAdapter.upsertMany(store.users, payload);
      store.status = 'idle';
    });
  },
});

export const usersCollectionActions = usersCollectionSlice.actions;

export default usersCollectionSlice.reducer;
