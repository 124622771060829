import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import _uniq from 'lodash/uniq';
import _omit from 'lodash/omit';
import _defaultsDeep from 'lodash/defaultsDeep';

import chatThunksV2 from './chatThunksV2';
import { ChatTypesENUM } from 'src/types';
import type { ChannelMetaDataType, IChannel, IMessage, IUser, MediaItemType } from 'src/types';
import helpers from 'src/utils/helpers';
import {
  getPendingMessages,
  setPendingMessages,
  // errorAllPendtingMessages,
} from './chatSliceUtils';
import { toast } from 'react-toastify';
import { t } from 'src/utils/getTranslation';
import type { IChannelVisiblePayload, ReactionType } from 'src/types/chatTypes';
import { MessageTypeENUM } from 'src/types/chatTypes';
import type { NewMessageMetaType } from '../api/chatWs_v2';
import { SpecialMentionTypeENUM } from '../types/quillTypes';
import type { ChannelNotificationStatusType } from 'src/types/userTypes';
import { LoadingSatusENUM, type ChannelThreadItemType, type MediaStoreInnerItemKeyType, type MediaStoreType } from './types';
import * as chatStoreHelpers from './helpers';
import { sortByCreatedAt } from 'src/utils/sortByCreatedAt';

const chatSliceV2 = createSlice({
  name: 'chatPage-v2',
  initialState: chatStoreHelpers.getInitialState(),
  reducers: {
    handleNewChannel: (store, { payload }: PayloadAction<{
      channel: IChannel;
      meta?: ChannelMetaDataType;
    }>) => {
      const { channel } = payload;
      const { channelId } = channel;

      // Burn it with fire! But later
      if (store.channelDetailsObject[channelId]?.usersToChannel) {
        store.channelDetailsObject[channelId].usersToChannel = payload.channel.userToChannels!.map((userToChannel) => {
          return {
            ...userToChannel,
            userId: userToChannel?.userId || userToChannel.userId,
          };
        });
      }
      store.channelsObject[channelId] = { ...store.channelsObject[channelId], ...channel };

      store.channelsMeta[channelId] = payload.meta || {
        channelId,
        unreadMessagesCount: 0,
        isContainsMention: false,
        threads: {},
      };
      if (channel.isArchived) {
        store.archivedChannels.push(channelId);
        store.archivedChannels = _uniq(store.archivedChannels);
      } else if (channel.type === ChatTypesENUM.channel) {
        store.channels.push(channelId);
        store.channels = _uniq(store.channels);
      } else {
        store.dmChannels.push(channelId);
        store.dmChannels = _uniq(store.dmChannels);
      }
    },
    handleNewMessage_old: (store, { payload }: PayloadAction<{
      chatId: number;
      isMyMessage: boolean;
      message: IMessage;
    }>) => {
      if (payload.isMyMessage || payload.message.parentMessage) {
        return;
      }

      if (!store.channelDetailsObject[payload.chatId].hasMoreDown) {
        store.channelDetailsObject[payload.chatId].channelMessagesObject[payload.message.messageId] = payload.message;
        store.channelDetailsObject[payload.chatId].messages.push(payload.message.messageId);
      }

      store.channelsMeta[payload.chatId].unreadMessagesCount += 1;
    },
    handleNewMention: (store, { payload }: PayloadAction<{ channelId: number }>) => {
      store.channelsMeta[payload.channelId].isContainsMention = true;
    },
    handleChannelUpdate: (
      store,
      { payload }: PayloadAction<{
        channel: { channelId: number } & Partial<IChannel>;
        channelMeta?: ChannelMetaDataType;
      }>,
    ) => {
      const currentValue = store.channelsObject[payload.channel.channelId];

      if (typeof payload.channel.isArchived === 'boolean' && currentValue?.isArchived !== payload.channel.isArchived) {
        if (payload.channel.isArchived) {
          store.archivedChannels.push(payload.channel.channelId);
          store.channels = helpers.removeFromArray(store.channels, payload.channel.channelId);
        } else {
          store.channelsObject[payload.channel.channelId] = {
            ...store.channelsObject[payload.channel.channelId],
            ...payload.channel,
          };
          store.channels.push(payload.channel.channelId);
          store.archivedChannels = helpers.removeFromArray(
            store.archivedChannels,
            payload.channel.channelId,
          );
        }
      }

      store.channelsObject[payload.channel.channelId] = {
        ...currentValue,
        ...payload.channel,
      };

      if (payload.channelMeta) {
        store.channelsMeta[payload.channel.channelId] = {
          ...store.channelsMeta[payload.channel.channelId],
          ...payload.channelMeta,
        };
      }
    },
    handleChannelDelete: (store, { payload }: PayloadAction<{ channelId: number }>) => {
      const channel = store.channelsObject[payload.channelId];
      if (channel?.isArchived) {
        store.archivedChannels = helpers.removeFromArray(store.archivedChannels, payload.channelId);
      } else {
        store.channels = helpers.removeFromArray(store.channels, payload.channelId);
      }
      store.channelsObject = _omit(store.channelsObject, payload.channelId);
      store.channelDetailsObject = _omit(store.channelDetailsObject, payload.channelId);
    },
    handleChannelUsersUpdate: (store, { payload }: PayloadAction<{
      channel: IChannel;
      withMe: boolean;
    }>) => {
      const { channelId } = payload.channel;

      if (payload.withMe) {
        store.channelsObject[channelId].userToChannels = payload.channel.userToChannels;
        return;
      }

      store.channelsObject = _omit(store.channelsObject, channelId);
      if (payload.channel?.isArchived) {
        store.archivedChannels = helpers.removeFromArray(store.archivedChannels, channelId);
        return;
      }
      store.channels = helpers.removeFromArray(store.channels, channelId);
    },
    handleNewMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      message: IMessage;
      meta: NewMessageMetaType;
      user: IUser;
    }>) => {
      const { channelId, message, meta, user } = payload;

      const isMyMessage = message.authorId === user.userId;

      const isUserInMentions = meta.userMentionUserIds.some((id) => {
        return user.userId === id || id === SpecialMentionTypeENUM.channel;
      });
      if (!store.channelsMeta[channelId]) {
        store.channelsMeta[channelId] = {
          channelId,
          unreadMessagesCount: 0,
          isContainsMention: false,
          threads: {},
        };
      }

      if (isUserInMentions && !isMyMessage) {
        store.channelsMeta[channelId].isContainsMention = true;
      }

      if (meta.type === 'channel') {
        if (store.channelDetailsObject[channelId]) {
          store.channelDetailsObject[channelId].messages.push(message.messageId);
          store.channelDetailsObject[channelId].channelMessagesObject[message.messageId] = message;
        }
        if (!isMyMessage && message.type !== MessageTypeENUM.action) {
          store.channelsMeta[channelId].unreadMessagesCount += 1;
          store.channelsMeta[channelId].lastMessageDate = new Date().toISOString();
        }
      } else {
        const isUserRealtedToMessage = meta.userAddedToThreadIds.some((id) => {
          return user.userId === id;
        });

        const parentId = meta.parentMessageId!;

        const storedParent = store.channelDetailsObject[channelId]?.channelMessagesObject[parentId];
        if (storedParent) {
          store.channelDetailsObject[channelId].channelMessagesObject[parentId].childMessages!.push(message);
        }

        if (store.threads[parentId]) {
          store.threads[parentId].messages.push(message.messageId);
          store.threads[parentId].messagesObject[message.messageId] = message;
          if (!store.threads[parentId].firstUnreadMessageId) {
            store.threads[parentId].firstUnreadMessageId = message.hasBeenRead ? null : message.messageId;
          }
        }

        if (!store.channelsMeta[channelId].threads[parentId]) {
          store.channelsMeta[channelId].threads[parentId] = {
            isContainsMention: false,
            parentMessageId: parentId,
            unreadMessagesCount: 0,
            isParentUnread: false,
          };
        }

        if (!isMyMessage && (isUserInMentions || isUserRealtedToMessage)) {
          store.channelsMeta[channelId].threads[parentId].unreadMessagesCount += 1;
          store.channelsMeta[channelId].threads[parentId].isContainsMention = true;
        }

        const isParentUnread = store.channelsMeta[channelId].threads[parentId].isParentUnread;
        if (!isMyMessage && !isParentUnread && store.channelsMeta[channelId].threads[parentId].unreadMessagesCount === 1) {
          store.channelsMeta[channelId].unreadMessagesCount += 1;
        }
      }
    },

    handleNewSendingMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      parentMessageId?: number | null;
      message: IMessage;
      userId: number;
    }>) => {
      const { channelId, parentMessageId, message } = payload;

      if (parentMessageId) {
        store.threads[parentMessageId].pendingMessages.sending.push(message);
        store.threads[parentMessageId].pendingMessages.errored = helpers.removeFromArray(
          store.threads[parentMessageId].pendingMessages.errored,
          (i) => i.messageId === message.messageId,
        );
      } else {
        store.channelDetailsObject[channelId].pendingMessages.errored = helpers.removeFromArray(
          store.channelDetailsObject[channelId].pendingMessages.errored,
          (i) => i.messageId === message.messageId,
        );
        store.channelDetailsObject[channelId].pendingMessages.sending.push(message);
      }

      setPendingMessages(
        parentMessageId ? 'thread' : 'channel',
        parentMessageId || channelId,
        payload.userId,
        parentMessageId
          ? store.threads[parentMessageId].pendingMessages
          : store.channelDetailsObject[channelId].pendingMessages,
      );
    },
    deleteErroredMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      messageId: number;
      userId: number;
      parentMessageId?: number;
    }>) => {
      if (payload.parentMessageId) {
        store.threads[payload.parentMessageId].pendingMessages.errored = helpers.removeFromArray(
          store.threads[payload.parentMessageId].pendingMessages.errored,
          (i) => i.messageId === payload.messageId,
        );

        setPendingMessages(
          'thread',
          payload.parentMessageId,
          payload.userId,
          store.threads[payload.parentMessageId].pendingMessages,
        );
      } else {
        store.channelDetailsObject[payload.channelId].pendingMessages.errored = helpers.removeFromArray(
          store.channelDetailsObject[payload.channelId].pendingMessages.errored,
          (i) => i.messageId === payload.messageId,
        );

        setPendingMessages(
          'channel',
          payload.channelId,
          payload.userId,
          store.channelDetailsObject[payload.channelId].pendingMessages,
        );
      }
    },
    deleteMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      messageId: number;
      parentMessageId?: number;
    }>) => {
      const removeMessage = (messageId: number) => {
        if (!store.channelDetailsObject[payload.channelId]) {
          return;
        }

        store.channelDetailsObject[payload.channelId].messages = helpers.removeFromArray(
          store.channelDetailsObject[payload.channelId].messages,
          messageId,
        );

        const channelMessages = { ...store.channelDetailsObject[payload.channelId].channelMessagesObject };
        delete channelMessages[payload.messageId];
        store.channelDetailsObject[payload.channelId].channelMessagesObject = channelMessages;
      };
      if (store.channelDetailsObject[payload.channelId]?.pinnedMessages?.some((pinnedMessage) => pinnedMessage.messageId === payload.messageId)) {
        store.channelDetailsObject[payload.channelId].pinnedMessages = store.channelDetailsObject[payload.channelId].pinnedMessages.filter(
          (message) => message.messageId !== payload.messageId,
        );
      }
      if (payload.parentMessageId) {
        const threadData = store.threads?.[payload.parentMessageId] || {};

        if (Object.values(threadData).length) {
          threadData.messages = helpers.removeFromArray(
            threadData.messages,
            payload.messageId,
          );
        }
        const openedThreadData = store.channelDetailsObject[payload.channelId]?.channelMessagesObject[payload.parentMessageId];

        if (openedThreadData?.childMessages?.length) {
          openedThreadData.childMessages = openedThreadData.childMessages.filter((childMessage) => {
            return childMessage.messageId !== payload.messageId;
          });
        }

        if (
          !openedThreadData?.childMessages?.length &&
          store.channelDetailsObject[payload.channelId]?.channelMessagesObject[payload.parentMessageId].deletedAt
        ) {
          if (store.openedThread.parentMessageId === payload.parentMessageId) {
            store.openedThread = {
              ...store.openedThread,
              parentMessageId: null,
              channelId: null,
            };
          }
          delete store.threads?.[payload.parentMessageId];
          delete store.channelDetailsObject[payload.channelId].channelMessagesObject[payload.parentMessageId];
          removeMessage(payload.parentMessageId);
        }

        const channelMessages = { ...threadData.messagesObject };
        delete channelMessages[payload.messageId];
        threadData.messagesObject = channelMessages;
      } else {
        if (store.channelDetailsObject?.[payload.channelId]?.channelMessagesObject?.[payload.messageId]?.childMessages?.length) {
          store.channelDetailsObject[payload.channelId].channelMessagesObject[payload.messageId] = {
            ...store.channelDetailsObject[payload.channelId].channelMessagesObject[payload.messageId],
            messageText: [],
            deletedAt: new Date(), // we need to set deletedAt to show default deleted message title in realtime (before page reload)
          };

          return;
        }

        if (store.openedThread.parentMessageId === payload.messageId) {
          store.openedThread = {
            ...store.openedThread,
            parentMessageId: null,
            channelId: null,
          };
        }

        removeMessage(payload.messageId);
      }
    },
    updateMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      messageId: number;
      messageData: Partial<IMessage>;
      parentMessageId?: number;
    }>) => {
      const channelDetails = store.channelDetailsObject?.[payload.channelId];

      if (payload.messageData.isPinned &&
        !store.channelDetailsObject?.[payload.channelId]?.pinnedMessagesIds.includes(payload.messageId)
      ) {
        const targetMessage = store.channelDetailsObject?.[payload.channelId]?.channelMessagesObject[payload.messageId] ??
          store.threads[payload.parentMessageId as number]?.messagesObject[payload.messageId] ??
          payload.messageData;
        targetMessage.isPinned = true;

        const newPinnedMessage = store.channelDetailsObject?.[payload.channelId]?.channelMessagesObject[payload.messageId] ??
          store.threads[payload.parentMessageId as number]?.messagesObject[payload.messageId] ??
          payload.messageData as IMessage;

        if (channelDetails && channelDetails.pinnedMessages) {
          channelDetails.pinnedMessages.push(newPinnedMessage);
        }

        if (channelDetails && !channelDetails.pinnedMessages) {
          channelDetails.pinnedMessages = [newPinnedMessage];
        }

        channelDetails?.pinnedMessagesIds?.push(payload.messageId);

        store.channelDetailsObject[payload.channelId]?.pinnedMessages.sort(sortByCreatedAt.desc);
      }

      if (payload.messageData.isPinned === false) {
        if (channelDetails?.pinnedMessages) {
          channelDetails.pinnedMessages = channelDetails.pinnedMessages.filter(
            (message) => message.messageId !== payload.messageId,
          );
        }
        if (!channelDetails?.pinnedMessages) {
          channelDetails.pinnedMessages = [];
        }
        channelDetails.pinnedMessagesIds = channelDetails?.pinnedMessagesIds?.filter((id) => id !== payload.messageId) || [];
      }

      if (payload.parentMessageId) {
        const oldMessage = store.threads[payload.parentMessageId]?.messagesObject[payload.messageId];
        if (!oldMessage) {
          return;
        }

        store.threads[payload.parentMessageId].messagesObject[payload.messageId] = {
          ...oldMessage,
          ...payload.messageData,
        };
      } else {
        const oldMessage = store.channelDetailsObject[payload.channelId]?.channelMessagesObject?.[payload.messageId];
        if (!oldMessage) {
          return;
        }

        store.channelDetailsObject[payload.channelId].channelMessagesObject[payload.messageId] = {
          ...oldMessage,
          ...payload.messageData,
        };
      }
    },
    handlePinnedMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      message: IMessage;
      isAdded: boolean;
      parentMessageId?: number;
    }>) => {
      const channelDetails = store.channelDetailsObject[payload.channelId];
      if (payload.isAdded) {
        channelDetails.pinnedMessages.push(payload.message);

        channelDetails.pinnedMessages.sort((messageAId, messageBId) => {
          return +new Date(messageBId.createdAt) - +new Date(messageAId.createdAt);
        });

        return;
      }

      channelDetails.pinnedMessages = channelDetails.pinnedMessages.filter(
        (message) => message.messageId !== payload.message.messageId,
      );
    },
    setOpenedaThreadData: (store, { payload }: PayloadAction<{
      channelId: number | null;
      parentMessageId: number | null;
      isLoading: boolean;
    }>) => {
      store.openedThread = payload;
    },
    clearFirstUnreadMessage: (store, { payload }: PayloadAction<{
      channelId: number;
    }>) => {
      if (!store.channelDetailsObject[payload.channelId]) {
        return;
      }
      store.channelDetailsObject[payload.channelId].firstUnreadMessageId = null;
    },
    handleNewMediaItem: (store, { payload }: PayloadAction<{
      inputType: MediaStoreInnerItemKeyType;
      channelId: number;
      messageId?: number;
      files: MediaItemType[];
      setIncomingPayload?: boolean;
      parentMessageId: number | null;
    }>) => {
      if (!store.mediaItems?.[payload.channelId]) {
        store.mediaItems[payload.channelId] = {} as MediaStoreType[typeof payload.channelId];
      }
      if (!store.mediaItems?.[payload.channelId]?.[payload.inputType]) {
        store.mediaItems[payload.channelId][payload.inputType] = {
          channelId: payload.channelId,
          parentMessageId: payload.parentMessageId,
          messageId: payload.messageId,
          files: [],
        };
      }
      if (payload.setIncomingPayload) {
        store.mediaItems[payload.channelId][payload.inputType].files = payload.files;
        return;
      }

      store.mediaItems[payload.channelId][payload.inputType].files = [
        ...store.mediaItems[payload.channelId][payload.inputType].files,
        ...payload.files,
      ];
    },
    updateMediaItemData: (store, { payload }: PayloadAction<{
      inputType: MediaStoreInnerItemKeyType;
      channelId: number;
      fileId: string;
      fileData: Partial<MediaItemType>;
    }>) => {
      const channelFiles = store.mediaItems[payload.channelId][payload.inputType];
      channelFiles.files = channelFiles.files.map((file) => {
        if (file.id === payload.fileId) {
          return {
            ...file,
            ...payload.fileData,
          };
        }
        return file;
      });
    },
    deleteMediaItem: (store, { payload }: PayloadAction<{
      inputType: MediaStoreInnerItemKeyType;
      channelId: number;
      fileId?: string;
    }>) => {
      if (!payload.fileId) {
        store.mediaItems[payload.channelId][payload.inputType].files = [];
        return;
      }

      const index = store.mediaItems[payload.channelId][payload.inputType].files.findIndex((file) => file.id === payload.fileId);
      store.mediaItems[payload.channelId][payload.inputType].files.splice(index, 1);
    },
    handleNewReadMessages: (store, { payload }: PayloadAction<{
      channelId: number;
      parentMessageId?: number;
      lastViewedMessageTime: string;
    }>) => {
      const { channelId, lastViewedMessageTime, parentMessageId } = payload;
      const timestamp = new Date(lastViewedMessageTime);

      if (parentMessageId) {
        if (!store.threads[parentMessageId]) {
          return;
        }
        store.threads[parentMessageId]?.messages.forEach((messageId) => {
          const message = store.threads[parentMessageId].messagesObject[messageId];
          const messageCreatedAt = new Date(message.createdAt);
          if (message.hasBeenRead || messageCreatedAt > timestamp) {
            return;
          }
          store.threads[parentMessageId].messagesObject[messageId].hasBeenRead = true;
        });
      } else {
        store.channelDetailsObject[channelId]?.messages.forEach((messageId) => {
          const message = store.channelDetailsObject[channelId].channelMessagesObject[messageId];
          const messageCreatedAt = new Date(message.createdAt);
          if (message.hasBeenRead || messageCreatedAt > timestamp) {
            return;
          }
          store.channelDetailsObject[channelId].channelMessagesObject[messageId].hasBeenRead = true;
        });
      }
    },
    setMediaDownloading: (store, { payload }: PayloadAction<{
      isDownloading: boolean;
      mediaItemId: number;
    }>) => {
      store.downloadingMediaItemIdsObj[payload.mediaItemId] = payload.isDownloading;
    },
    toggleReaction: (store, { payload }: PayloadAction<{
      channelId: number;
      parentMessageId: number | null;
      messageId: number;
      reaction: ReactionType;
      actionType: 'add' | 'remove';
    }>) => {
      const message = payload.parentMessageId
        ? store.threads[payload.parentMessageId]?.messagesObject[payload.messageId]
        : store.channelDetailsObject[payload.channelId]?.channelMessagesObject[payload.messageId];

      if (!message) {
        return;
      }

      let reactions = [...(message.reactions || [])];

      if (payload.actionType === 'remove') {
        const user = payload.reaction.reactions[0].user;
        reactions = message.reactions!.reduce<ReactionType[]>((acc, reaction) => {
          if (reaction.shortcode !== payload.reaction.shortcode) {
            acc.push(reaction);
            return acc;
          }
          const nestedReactions = [...reaction.reactions];
          const reactionIndex = nestedReactions.findIndex((i) => i.user.userId === user.userId);
          nestedReactions.splice(reactionIndex, 1);
          if (nestedReactions.length) {
            acc.push({
              shortcode: reaction.shortcode,
              reactions: nestedReactions,
            });
          }

          return acc;
        }, []);
      } else {
        const existingReactionIndex = reactions.findIndex((reaction) => reaction.shortcode === payload.reaction.shortcode);
        if (existingReactionIndex === -1) {
          reactions.push(payload.reaction);
        } else {
          reactions[existingReactionIndex].reactions.push(payload.reaction.reactions[0]);
        }
      }

      if (payload.parentMessageId) {
        store.threads[payload.parentMessageId]!.messagesObject[payload.messageId].reactions = reactions;
      } else {
        store.channelDetailsObject[payload.channelId]!.channelMessagesObject[payload.messageId].reactions = reactions;
      }
    },
    setChannelNotificationStatuses: (store, { payload }: PayloadAction<ChannelNotificationStatusType[]>) => {
      store.channelNotificationStatuses = payload;
    },

    setTreadChannelMessageToFirstStep(store) {
      const initialLength = Math.min(store.threadsChannelMessages.length, 5);
      store.threadsChannelMessages.length = initialLength;
    },
    handleChannelBecomeVisible: (store, { payload }: PayloadAction<IChannelVisiblePayload>) => {
      const { channel, meta } = payload;
      if (channel.type === ChatTypesENUM.channel) {
        return;
      }

      store.channelsObject[channel.channelId] = { ...store.channelsObject[channel.channelId], ...channel };

      store.channelsMeta[channel.channelId] = meta || {
        channelId: channel.channelId,
        unreadMessagesCount: 0,
        isContainsMention: false,
        threads: {},
      };
      store.dmChannels.push(channel.channelId);
      store.dmChannels = _uniq(store.dmChannels);
    },

    markChannelAsDirty: (store, { payload }: PayloadAction<{ channelId: number }>) => {
      if (!store.channelDetailsObject?.[payload.channelId]) {
        return;
      }
      store.channelDetailsObject[payload.channelId].markedAsDirty = true;
    },

    markChannelAsClean: (store, { payload }: PayloadAction<{ channelId: number }>) => {
      if (!store.channelDetailsObject?.[payload.channelId]) {
        return;
      }
      store.channelDetailsObject[payload.channelId].markedAsDirty = false;
    },
    setSelectedChannelId: (store, { payload }: PayloadAction<number | null>) => {
      store.selectedChannelId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(chatThunksV2.getMyChannels.fulfilled, (store, { payload }) => {
      store.channelsObject = _defaultsDeep(payload.channelsObject, store.channelsObject);
      store.channels = payload.channels;
      store.dmChannels = payload.dmChannels;
      store.archivedChannels = payload.archivedChannels;
      store.channelsMeta = payload.channelsMeta;
      store.isChannelsLoading = false;
    });
    builder.addCase(chatThunksV2.getMyChannels.pending, (store) => {
      if (
        store.channels.length ||
        store.dmChannels.length ||
        store.archivedChannels.length
      ) {
        return;
      }
      store.isChannelsLoading = true;
    });
    builder.addCase(chatThunksV2.getMyChannels.rejected, (store, { error }) => {
      console.error('Failed to get my channels', error);

      store.getMyChannelsError = error;
      store.isChannelsLoading = false;
    });
    builder.addCase(chatThunksV2.getChannelData.fulfilled, (store, { payload, meta }) => {
      const {
        channel,
        messages,
        messagesObject,
        usersToChannel,
        pinnedMessagesIds,
      } = payload;

      store.channelsObject[channel.channelId] = channel;

      const storedPendingMessages = getPendingMessages('channel', channel.channelId, payload.userId);
      // const pendingMessages = errorAllPendtingMessages(storedPendingMessages);

      const oldData = store.channelDetailsObject[channel.channelId];
      store.channelDetailsObject[channel.channelId] = {
        ...oldData,
        isPartiallyLoaded: false,
        channelId: channel.channelId,
        messages: messages || oldData.messages,
        pendingMessages: storedPendingMessages,
        channelMessagesObject: messagesObject || oldData.channelMessagesObject,
        usersToChannel: usersToChannel || oldData.usersToChannel,
        hasMoreUp: payload.hasMoreUp ?? oldData.hasMoreUp,
        isLoadingUp: false,
        hasMoreDown: payload.hasMoreDown ?? oldData.hasMoreDown,
        isLoadingDown: false,
        firstUnreadMessageId: payload.firstUnreadMessageId || null,
        lastReadMessageTimestamp: payload.lastReadMessageTimestamp || oldData?.lastReadMessageTimestamp,
        pinnedMessagesIds: pinnedMessagesIds || oldData?.pinnedMessagesIds || [],
        markedAsDirty: false,
      };

      if (channel.type !== ChatTypesENUM.channel && meta.arg.shouldSetVisible) {
        store.dmChannels = _uniq([...store.dmChannels, channel.channelId]);
      }
      if (!store.channelsMeta[channel.channelId]) {
        store.channelsMeta[channel.channelId] = {
          channelId: channel.channelId,
          unreadMessagesCount: 0,
          isContainsMention: false,
          threads: {},
        };
      }
    });

    builder.addCase(chatThunksV2.getPinnedChannelMessages.fulfilled, (store, { payload, meta }) => {
      const { pinnedMessages, messagesObject } = payload;
      const channelId = meta.arg.channelId;
      const currentValue = store.channelDetailsObject[channelId];
      currentValue.pinnedMessages = pinnedMessages || currentValue.pinnedMessages;
      currentValue.pinnedMessages.sort(sortByCreatedAt.desc);
      currentValue.pinnedMessagesLoadingStatus = LoadingSatusENUM.success;
      if (!currentValue.pinnedMessagesIds) {
        currentValue.pinnedMessagesIds = [];
      }
      currentValue.pinnedMessagesIds = pinnedMessages.map((message) => message.messageId);
      currentValue.channelMessagesObject = {
        ...currentValue.channelMessagesObject,
        ...messagesObject,
      };
    });

    builder.addCase(chatThunksV2.getPinnedChannelMessages.rejected, (store, { meta }) => {
      store.channelDetailsObject[meta.arg.channelId].pinnedMessagesLoadingStatus = LoadingSatusENUM.error;
    });

    builder.addCase(chatThunksV2.getPinnedChannelMessages.pending, (store, { meta }) => {
      store.channelDetailsObject[meta.arg.channelId].pinnedMessagesLoadingStatus = LoadingSatusENUM.pending;
    });

    builder.addCase(chatThunksV2.getChannelData.rejected, (store, { error }) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.error('Failed to get channel data:', error);

      if (['Request failed with status code 404', 'Not found'].includes(error.message!)) {
        return;
      }

      toast.error(t('errors:server.internal.unexpected'));
    });

    builder.addCase(chatThunksV2.sendMessage.fulfilled, (store, { payload }) => {
      const { channelId, parentMessageId, message, temporaryMessageId } = payload;

      const channelDetails = store.channelDetailsObject[channelId];
      if (!channelDetails) {
        return;
      }

      const updatePendingMessages = () => {
        setPendingMessages(
          parentMessageId ? 'thread' : 'channel',
          parentMessageId || channelId,
          payload.userId,
          parentMessageId
            ? store.threads[parentMessageId].pendingMessages
            : store.channelDetailsObject[channelId].pendingMessages,
        );
      };

      if (parentMessageId) {
        store.threads[parentMessageId].pendingMessages.sending = helpers.removeFromArray(
          store.threads[parentMessageId].pendingMessages.sending,
          (i) => i.messageId === temporaryMessageId,
        );

        if (payload.isFailed) {
          store.threads[parentMessageId].pendingMessages.errored.push(message);
          // TODO add handle for message with files
          if (!payload.message.media?.length) {
            updatePendingMessages();
          }
          return;
        }

        store.threads[parentMessageId].messagesObject[message.messageId] = message;
        store.threads[parentMessageId].messages.push(message.messageId);

        store.channelDetailsObject[channelId].channelMessagesObject[parentMessageId].childMessages?.push(message);
      } else {
        store.channelDetailsObject[channelId].pendingMessages.sending = helpers.removeFromArray(
          store.channelDetailsObject[channelId].pendingMessages.sending,
          (i) => i.messageId === temporaryMessageId,
        );

        if (payload.isFailed) {
          store.channelDetailsObject[channelId].pendingMessages.errored.push(message);
          // TODO add handle for message with files
          if (!payload.message.media?.length) {
            updatePendingMessages();
          }
          return;
        }

        if (!channelDetails.hasMoreDown) {
          store.channelDetailsObject[channelId].channelMessagesObject[message.messageId] = message;
          store.channelDetailsObject[channelId].messages.push(message.messageId);
        }
      }
      if (
        !store.dmChannels.includes(channelId) &&
        store.channelsObject[channelId].type !== ChatTypesENUM.channel
      ) {
        store.dmChannels.push(channelId);
      }
      updatePendingMessages();
    });

    builder.addCase(chatThunksV2.loadMoreMessages.pending, (store, { meta }) => {
      const key = meta.arg.anchorType === 'top' ? 'isLoadingUp' as const : 'isLoadingDown' as const;
      store.channelDetailsObject[meta.arg.channelId][key] = true;
    });
    builder.addCase(chatThunksV2.loadMoreMessages.fulfilled, (store, { payload, meta }) => {
      const key = meta.arg.anchorType === 'top' ? 'isLoadingUp' as const : 'isLoadingDown' as const;
      store.channelDetailsObject[meta.arg.channelId][key] = false;

      const channelDetails = store.channelDetailsObject[payload.channelId];

      const messages = [...channelDetails.messages];
      const channelMessagesObject = { ...channelDetails.channelMessagesObject };

      const newMessages = payload.messages.map((message) => {
        channelMessagesObject[message.messageId] = message;

        return message.messageId;
      });

      if (payload.direction === 'top') {
        messages.unshift(...newMessages);
      } else {
        messages.push(...newMessages);
      }

      store.channelDetailsObject[payload.channelId] = {
        ...channelDetails,
        hasMoreUp: payload.hasMoreUp,
        hasMoreDown: payload.hasMoreDown,
        messages,
        channelMessagesObject,
      };
    });
    builder.addCase(chatThunksV2.loadMoreMessages.rejected, (store, { error, meta }) => {
      const key = meta.arg.anchorType === 'top' ? 'isLoadingUp' as const : 'isLoadingDown' as const;
      store.channelDetailsObject[meta.arg.channelId][key] = false;

      console.error('Failed to load more messages:', error);

      toast.error(t('errors:server.internal.unexpected'));
    });

    builder.addCase(chatThunksV2.openThread.fulfilled, (store, { payload }) => {
      store.threads[payload.parentMessageId] = payload;
    });

    builder.addCase(chatThunksV2.markMessageAsUnread.fulfilled, (store, { payload, meta }) => {
      const { channelId, lastViewedMessageTime, userId, newMeta } = payload;

      store.channelsMeta[channelId] = newMeta;

      // For the future usage, when we will have thread unread logic;

      // if (meta.arg.parentMessageId) {
      //   const userInThread = store.threads[meta.arg.parentMessageId].userToThreads.find((userToThread) => userToThread.userId === userId);

      //   if (userInThread) {
      //     userInThread.lastViewedMessageTime = lastViewedMessageTime;
      //   } else {
      //     store.threads[meta.arg.parentMessageId].userToThreads.push({
      //       userId,
      //       lastViewedMessageTime,
      //       rootMessageId: meta.arg.message.messageId,
      //       userThreadId: userThreadId!,
      //     });
      //   }

      //   store.threads[meta.arg.parentMessageId].firstUnreadMessageId = meta.arg.message.messageId;
      //   return;
      // }

      const userInChannel = store.channelsObject[channelId].userToChannels!.find((userToChannel) => userToChannel.userId === userId);
      if (userInChannel) {
        userInChannel.lastViewedMessageTime = lastViewedMessageTime;
      }
      store.channelDetailsObject[payload.channelId].firstUnreadMessageId = meta.arg.message.messageId;
    });

    builder.addCase(chatThunksV2.markMessageAsRead.fulfilled, (store, { payload }) => {
      const { channelId, parentMessageId, lastViewedMessageTime, userId, newMeta } = payload;

      store.channelsMeta[channelId] = newMeta;

      if (parentMessageId) {
        const userToThreadIndex = store.threads[parentMessageId].userToThreads!.findIndex((userToThread) => userToThread.userId === userId);
        store.threads[parentMessageId].userToThreads![userToThreadIndex].lastViewedMessageTime = lastViewedMessageTime;
      } else {
        const userToChannelIndex = store.channelsObject[channelId].userToChannels!.findIndex((userToChannel) => userToChannel.userId === userId);
        store.channelsObject[channelId].userToChannels![userToChannelIndex].lastViewedMessageTime = lastViewedMessageTime;
      }
    });

    builder.addCase(chatThunksV2.getThreadChannelMessages.fulfilled, (store, { payload, meta }) => {
      if (meta.arg?.extraResults) {
        store.threadsChannelMessages.push(...payload.payload.map((i) => ({
          messageId: i.messageId,
          channelId: i.channelId!,
        })));
      } else {
        store.threadsChannelMessages = payload.payload.map((i) => ({
          messageId: i.messageId,
          channelId: i.channelId!,
        }));
      }

      payload.payload?.forEach((message) => {
        store.channelsObject[message.channelId!] = {
          ...store.channelsObject[message.channelId!],
          ...message.channel,
        };

        const oldData = store.channelDetailsObject[message.channelId!] ?? new chatStoreHelpers.ChannelDefaultData(message.channelId!, true);

        store.channelDetailsObject[message.channelId!] = {
          ...oldData,
          messages: [...oldData.messages, message.messageId!],
          channelMessagesObject: {
            ...oldData.channelMessagesObject,
            [message.messageId!]: {
              ...message,
              childMessages: message.childMessages?.map(chatStoreHelpers.getChildMessagesShortDetails),
            },
          },
          usersToChannel: message.channel?.userToChannels ?? oldData.usersToChannel,
        };

        if (message.childMessages) {
          const threadData = {
            ids: [],
            messages: {},
          } as { ids: number[]; messages: Record<string, IMessage> };

          message.childMessages.forEach((threadMessage) => {
            threadData.ids.push(threadMessage.messageId!);
            threadData.messages[threadMessage.messageId!] = threadMessage;
          });

          store.threads[message.messageId] = {
            ...store.threads[message.messageId],
            channelId: message.channelId!,
            firstUnreadMessageId: payload.meta.unreadMessages[message.messageId!],
            messages: threadData.ids,
            messagesObject: threadData.messages,
            parentMessageId: message.messageId,
            userToThreads: payload.meta.threadUsers[message.messageId!],
            pendingMessages: {
              errored: store.threads[message.messageId]?.pendingMessages.errored ?? [],
              sending: store.threads[message.messageId]?.pendingMessages.sending ?? [],
            },
          } as ChannelThreadItemType;
        }
      });
    });

    builder.addCase(chatThunksV2.closeDmChannel.pending, (store, { meta }) => {
      const channelId = meta.arg.channelId;
      if (!channelId) {
        return;
      }
      store.dmChannels = helpers.removeFromArray(store.dmChannels, channelId);
      store.channelsObject = _omit(store.channelsObject, channelId);
      store.channelsMeta = _omit(store.channelsMeta, channelId);
      store.channelDetailsObject = _omit(store.channelDetailsObject, channelId);
    });
  },
});

export const chatSliceV2Actions = chatSliceV2.actions;

export default chatSliceV2;
