import _debounce from 'lodash/debounce';
import dayjs from 'dayjs';

import { type ReactQuillExtendedType, type MediaItemType } from 'src/types';

import LocalStorageItem from 'src/utils/storage/LocalStorageItem';

type DraftStorageItemType = {
  channelId: number;
  parentMessageId: number | null;
  referencedMessageId: number | null;
  editingMessageId: number | null;
  text: string;
  mediaItems?: MediaItemType[];
  userId: number;
};

type DraftStorageType = {
  [key: `${'channel' | 'thread'}-${number}`]: DraftStorageItemType;
};

const defaultThreadData = {
  parentMessageId: null,
  referencedMessageId: null,
  editingMessageId: null,
  text: '',
};

const draftMessageStorage = new LocalStorageItem<{ [key: string]: DraftStorageType }>({ key: 'chat_draft-messages' });
const createDraftKey = (channelId: number, parentMessageId: number | null) => {
  return `${parentMessageId ? 'thread' : 'channel'}-${parentMessageId || channelId}` as const;
};
/**
 * To clear from all potential garbage
 */
const clearDraftMessageStorage = () => {
  const data = draftMessageStorage.get() || {};
  for (const draftItem in data) {
    if (draftItem in data) {
      const draftMessagesData = data[draftItem];
      Object.entries(draftMessagesData).forEach(([key, value]) => {
        if (!value?.text) {
          delete draftMessagesData[key as keyof typeof draftMessagesData];
        }
      });
    }
  }

  draftMessageStorage.set(data);
};
clearDraftMessageStorage();

const getDraftData = (channelId: number, parentMessageId: number | null, userId: number) => {
  const channelKey = createDraftKey(channelId, parentMessageId);
  const storedData = draftMessageStorage.get()?.[userId];

  if (storedData) {
    const channelDraft = storedData[channelKey];
    if (channelDraft) {
      return channelDraft;
    }
  }

  return {
    ...defaultThreadData,
    channelId,
    parentMessageId: parentMessageId || null,
    userId,
  };
};
const setDraftData = (data: DraftStorageItemType) => {
  const userKey = data.userId;
  const channelKey = createDraftKey(data.channelId, data.parentMessageId);
  const storedData = draftMessageStorage.get() || {};

  if (!storedData[userKey]) {
    storedData[userKey] = {};
  }

  storedData[userKey][channelKey] = data;
  draftMessageStorage.set(storedData);
};

type SetChannelDraftsArgumentsType = [number, Partial<Omit<DraftStorageItemType, 'parentMessageId' | 'channelId'>>, number];
type SetThreadDraftsArgumentsType = [number, number, Partial<Omit<DraftStorageItemType, 'parentMessageId' | 'channelId'>>, number ];

const DRAFT_DEBOUNCE_TIMEOUT = 1000;
const MAX_DRAFT_DEBOUNCE_TIMEOUT = 5000;
export const chatDrafts = {
  channel: {
    get: (channelId: number, userId: number): DraftStorageItemType => {
      return getDraftData(channelId, null, userId);
    },
    set: _debounce((...args: SetChannelDraftsArgumentsType | [boolean]) => {
      if (typeof args[0] === 'boolean') {
        return;
      }
      chatDrafts.channel.setImmediately(...args as SetChannelDraftsArgumentsType);
    }, DRAFT_DEBOUNCE_TIMEOUT, { maxWait: MAX_DRAFT_DEBOUNCE_TIMEOUT }),
    setImmediately: (...[channelId, data, userId]: SetChannelDraftsArgumentsType) => {
      chatDrafts.channel.set(false); // To exclude case when immediate call will be overrided with a debounced call
      const oldData = chatDrafts.channel.get(channelId, userId);
      setDraftData({
        ...oldData,
        ...data,
      });
    },
  },
  thread: {
    get: (channelId: number, parentMessageId: number, userId: number): DraftStorageItemType => {
      return getDraftData(channelId, parentMessageId, userId);
    },
    set: _debounce((...args: SetThreadDraftsArgumentsType | [boolean]) => {
      if (typeof args[0] === 'boolean') {
        return;
      }
      chatDrafts.thread.setImmediately(...args as SetThreadDraftsArgumentsType);
    }, DRAFT_DEBOUNCE_TIMEOUT, { maxWait: MAX_DRAFT_DEBOUNCE_TIMEOUT }),
    setImmediately: (...[channelId, parentMessageId, data, userId]: SetThreadDraftsArgumentsType) => {
      chatDrafts.thread.set(false); // To exclude case when immediate call will be overrided with a debounced call
      const oldData = chatDrafts.thread.get(channelId, parentMessageId, userId);
      setDraftData({
        ...oldData,
        ...data,
      });
    },
  },
};

export class InputFocusHandler {
  private element: HTMLInputElement | HTMLTextAreaElement | ReactQuillExtendedType | null = null;

  setInputElement = (element: HTMLInputElement | HTMLTextAreaElement | ReactQuillExtendedType) => {
    if (!element || element === this.element) {
      return;
    }

    this.element = element;
  };

  focus = (retiesCount = 0) => {
    if (!this.element) {
      return;
    }

    this.element.focus();

    if ('editor' in this.element) {
      this.setReactQuillCursorPositionAtEnd();
    }

    if (this.element !== document.activeElement) {
      if (retiesCount > 10) {
        return;
      }

      setTimeout(() => {
        this.focus(retiesCount + 1);
      }, 10 * (retiesCount + 1));
    }
  };

  setReactQuillCursorPositionAtEnd = () => {
    if (!this.element || !('editor' in this.element)) {
      return;
    }

    const editor = this.element.getEditor();
    editor.setSelection(editor.getLength() - 1, 0);
  };
}

export const getDateDifferent = (date1: string, date2: string) => {
  return Math.abs(dayjs(date1).diff(date2, 'days'));
};

export const getDayLabel = (value: {
  dateLabel: string;
  todayLabel: string;
  yesterdayLabel: string;
  sendedAt: string;
}) => {
  const dayDifferent = getDateDifferent(value.sendedAt, dayjs().format('YYYY-MM-DD'));

  switch (dayDifferent) {
    case 0:
      return value.todayLabel;
    case 1:
      return value.yesterdayLabel;
    default:
      return value.dateLabel;
  }
};

const EMPTY_CODE = '\\s*<code>\\s*<\\/code>\\s*';
const EMPTY_CODE_BLOCK = '<pre[^>]*>\\s*<\\/pre>';
const EMPTY_LIST = '<(o|u)l>(<li>(<br>|\\s*)<\\/li>){0,}<\\/(o|u)l>';
const EMPTY_MESAGE_TEXT_REG_EXP = new RegExp(`^(<(p|blockquote)>(<br>|\\s*|${EMPTY_CODE})<\\/(p|blockquote)>|${EMPTY_CODE_BLOCK}|${EMPTY_LIST}){0,}$`);
export const clearMessageText = (text: string) => {
  if (EMPTY_MESAGE_TEXT_REG_EXP.test(text)) {
    return '';
  }

  return text.trim();
};
